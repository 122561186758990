<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <b-form-group
        id="legalAddressInputGroup1"
        label-cols-md="3"
        label="Юридический адрес"
        label-for="legal-address"
      >
        <b-form-input
          id="legal-address"
          v-model.trim="$v.form.legalAddress.$model"
          type="text"
          name="legal-address"
          autocomplete="address"
          autofocus
          :state="chkState('legalAddress')"
          aria-describedby="requisitesLegalAddress"
        />
        <b-form-invalid-feedback id="requisitesLegalAddress">
          <span v-if="!$v.form.legalAddress.required">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="factualAddressInputGroup2"
        label-cols-md="3"
        label="Фактический адрес"
        label-for="actual-address"
      >
        <b-form-input
          id="actual-address"
          v-model.trim="$v.form.factualAddress.$model"
          type="text"
          name="actual-address"
          autocomplete="address"
          :state="chkState('factualAddress')"
          aria-describedby="requisitesFactualAddress"
        />
        <b-form-invalid-feedback id="requisitesFactualAddress">
          <span v-if="!$v.form.factualAddress.required">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="authorizedPersonInputGroup3"
        label-cols-md="3"
        label="Уполномоченное для подписания договора лицо"
        label-for="authorized-person"
      >
        <b-form-input
          id="authorized-person"
          v-model.trim="$v.form.authorizedPerson.$model"
          type="text"
          name="authorized-person"
          autocomplete="authorized-person"
        />
      </b-form-group>
      <b-form-group
        id="INNInputGroup4"
        label-cols-md="3"
        label="ИНН"
        label-for="tin"
      >
        <b-form-input
          id="tin"
          v-model.trim="$v.form.INN.$model"
          type="text"
          name="tin"
          autocomplete="tin"
          :state="chkState('INN')"
          aria-describedby="requisitesINN"
          :disabled="true"
        />
        <b-form-invalid-feedback id="requisitesINN ">
          <span v-if="!$v.form.INN.isInn">ИНН должен содержать 10 или 12 цифр</span>
          <span v-if="!$v.form.INN.isInnFormat">Неправильный формат ИНН</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="INNFile"
        label-cols-md="3"
        label="ИНН Файл"
        label-for="tin"
      >
        <b-form-file
          v-model="INNFile"
          v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
          accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
          class="mt-3"
          placeholder="Файл не выбран"
          browse-text="Выбрать"
          drop-placeholder="Перетащите файл сюда"
          @input="uploadFile('INNFile')"
        />
        <div
          v-if="form.INNFile && form.INNFile.file"
          class="mt-3"
        >
          Загруженный файл:
          <a
            :href="'/files/'+form.INNFile.file.guid"
            target="_blank"
            @click.prevent="downloadFile(form.INNFile.file)"
          >{{ form.INNFile.file.originalName }}</a>
        </div>
      </b-form-group>
      <b-form-group
        id="KPPInputGroup5"
        label-cols-md="3"
        label="КПП"
        label-for="cio"
      >
        <b-form-input
          id="cio"
          v-model.trim="$v.form.KPP.$model"
          type="text"
          name="cio"
          autocomplete="cio"
          :state="chkState('KPP')"
          aria-describedby="requisitesKpp"
        />
        <b-form-invalid-feedback id="requisitesKpp ">
          <span v-if="!$v.form.KPP.isKpp">Неправильный формат КПП</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="checkingAccountInputGroup6"
        label-cols-md="3"
        label="Расчетный счет"
        label-for="settlement-account"
      >
        <b-form-input
          id="settlement-account"
          v-model.trim="$v.form.checkingAccount.$model"
          type="text"
          name="settlement-account"
          autocomplete="settlement-account"
          :state="chkState('checkingAccount')"
          aria-describedby="requisitesCheckingAccount"
        />
        <b-form-invalid-feedback id="requisitesCheckingAccount ">
          <span v-if="!$v.form.checkingAccount.isRs">Неправильный формат расчетного счета</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="OKVEDInputGroup7"
        label-cols-md="3"
        label="ОКВЭД"
        label-for="okved"
      >
        <b-form-input
          id="okved"
          v-model.trim="$v.form.OKVED.$model"
          type="text"
          name="okved"
          autocomplete="okved"
          :state="chkState('OKVED')"
          aria-describedby="requisitesOKVED"
        />
        <b-form-invalid-feedback id="requisitesOKVED">
          <span v-if="!$v.form.OKVED.isRs">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="OGRNInputGroup8"
        label-cols-md="3"
        label="ОГРН"
        label-for="bin"
      >
        <b-form-input
          id="bin"
          v-model.trim="$v.form.OGRN.$model"
          type="text"
          name="bin"
          autocomplete="bin"
          :state="chkState('OGRN')"
          aria-describedby="requisitesOGRN"
        />
        <b-form-invalid-feedback id="requisitesOGRN">
          <span v-if="!$v.form.OGRN.numeric || !$v.form.OGRN.minLength || !$v.form.OGRN.maxLength">Неправильный формат ОГРН</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="OGRNDateInputGroup9"
        label-cols-md="3"
        label="Дата регистрации ОГРН"
        label-for="date-of-registration-of-bin"
        :state="chkState('OGRNDate')"
        :invalid-feedback="'Поле обязательно для заполнения'"
      >
        <el-date-picker-input
          id="date-of-registration-of-bin"
          v-model.trim="$v.form.OGRNDate.$model"
          type="date"
          name="date-of-registration-of-bin"
          autocomplete="date-of-registration-of-bin"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          :state="chkState('OGRNDate')&& false"
          aria-describedby="requisitesOGRNDate"
        />
        <b-form-invalid-feedback id="requisitesOGRNDate">
          <span v-if="$v.form.OGRNDate.$model == 'Invalid Date'">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="OGRNFile"
        label-cols-md="3"
        label="ОГРН Файл"
        label-for="tin"
      >
        <b-form-file
          v-model="OGRNFile"
          v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
          accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
          class="mt-3"
          placeholder="Файл не выбран"
          browse-text="Выбрать"
          drop-placeholder="Перетащите файл сюда"
          @input="uploadFile('OGRNFile')"
        />
        <div
          v-if="form.OGRNFile"
          class="mt-3"
        >
          Загруженный файл:
          <a
            :href="'/files/'+form.OGRNFile.file.guid"
            target="_blank"
            @click.prevent="downloadFile(form.OGRNFile.file)"
          >{{ form.OGRNFile.file.originalName }}</a>
        </div>
      </b-form-group>
      <b-form-group
        id="bankNameInputGroup10"
        label-cols-md="3"
        label="Наименование банка"
        label-for="name-of-the-bank"
      >
        <b-form-input
          id="name-of-the-bank"
          v-model.trim="$v.form.bankName.$model"
          type="text"
          name="name-of-the-bank"
          autocomplete="name-of-the-bank"
          :state="chkState('bankName')"
          aria-describedby="requisitesBankName"
        />
        <b-form-invalid-feedback id="requisitesBankName">
          <span>Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="correspondentAccountInputGroup11"
        label-cols-md="3"
        label="Корреспондентский счет"
        label-for="correspondent-account"
      >
        <b-form-input
          id="correspondent-account"
          v-model.trim="$v.form.correspondentAccount.$model"
          type="text"
          name="correspondent-account"
          autocomplete="correspondent-account"
          :state="chkState('correspondentAccount')"
          aria-describedby="requisitesCorrespondentAccount"
        />
        <b-form-invalid-feedback id="requisitesCorrespondentAccount ">
          <span v-if="!$v.form.correspondentAccount.isRs">Неправильный формат корреспондентского счета</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="BIKInputGroup12"
        label-cols-md="3"
        label="БИК"
        label-for="bik"
      >
        <b-form-input
          id="bik"
          v-model.trim="$v.form.BIK.$model"
          type="text"
          name="BIK"
          autocomplete="bik"
          :state="chkState('BIK')"
          aria-describedby="requisitesBIK"
        />
        <b-form-invalid-feedback id="requisitesBIK ">
          <span v-if="!$v.form.BIK.isBik">Неправильный формат БИК</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="authorizedPersonFile"
        label-cols-md="3"
        label="Файл подтверждающий уполномоченное для подписания договора лицо"
        label-for="tin"
      >
        <b-form-file
          v-model="authorizedPersonFile"
          v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
          accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
          class="mt-3"
          placeholder="Файл не выбран"
          browse-text="Выбрать"
          drop-placeholder="Перетащите файл сюда"
          @input="uploadFile('authorizedPersonFile')"
        />
        <div
          v-if="form.authorizedPersonFile"
          class="mt-3"
        >
          Загруженный файл:
          <a
            :href="'/files/'+form.authorizedPersonFile.file.guid"
            target="_blank"
            @click.prevent="downloadFile(form.authorizedPersonFile.file)"
          >{{ form.authorizedPersonFile.file.originalName }}</a>
        </div>
      </b-form-group>
      <div slot="footer">
        <b-button
          v-if="$store.getters.isAdmin"
          type="submit"
          variant="primary"
          :disabled="loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElDatePickerInput from '../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {
  validation,
  isInn,
  isInnFormat,
  isKpp,
  isRs,
  isBik,
  isKs,
} from '../components/mixins/validation';
import {
  required,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import notifications from '../components/mixins/notifications';
import {
  contractorRead,
  contractorUpdate,
  documentCreate,
  downloadFile,
} from '../services/api';
import moment from 'moment';

const formShape = {
  legalAddress: '',
  factualAddress: '',
  authorizedPerson: '',
  INN: '',
  INNFile: null,
  KPP: '',
  checkingAccount: '',
  OKVED: '',
  OGRN: '',
  OGRNDate: '',
  OGRNFile: null,
  bankName: '',
  correspondentAccount: '',
  BIK: '',
  authorizedPersonFile: null,
};

export default {
  name: 'Requisites',
  mixins: [validationMixin, validation, notifications],
  components: {
    ElDatePickerInput,
  },
  data() {
    return {
      INNFile: null,
      OGRNFile: null,
      authorizedPersonFile: null,
      form: Object.assign({}, formShape),
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const sendData = this.form;
      sendData.OGRNDate = moment(sendData.OGRNDate).format();
      return sendData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      legalAddress: {
      },
      factualAddress: {
      },
      authorizedPerson: '',
      INN: {
      },
      KPP: {
      },
      checkingAccount: {
      },
      OKVED: {
      },
      OGRN: {
      },
      OGRNDate: {
        required,
      },
      bankName: {
      },
      correspondentAccount: {
      },
      BIK: {
      },
    },
  },
  mounted() {
    this.contractorRead();
  },
  methods: {
    downloadFile,
    async contractorRead() {
      this.loading = true;
      const response = await contractorRead(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        for (const [key, value] of Object.entries(this.form)) {
          this.form[key] = response.data.contractor[key];
        }
        if (this.form.OGRNDate) {
          this.form.OGRNDate = moment(this.form.OGRNDate).format();
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorUpdate(
          this.$store.state.user.ownerId,

          this.formStr,
        );
        if (response && response.status === 200) {
          if (response.data.OGRNDate) {
            this.form.OGRNDate = moment(response.data.OGRNDate).format();
          }
          this.showSuccess('Реквизиты обновлены');
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else return !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadFile(key) {
      const file = this.$data[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError(
          'Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.',
        );
      } else {
        this.getBase64(file).then((data) => {
          this.loading = true;
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.documentCreate(key, upload);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
